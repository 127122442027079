.react-datepicker-popper {
  z-index: 3;
  padding-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100%;

  label:not(:last-child) {
    margin-bottom: 0;
  }
}

.react-datepicker__triangle {
  &:before,
  &:after {
    content: none !important;
    display: none !important;
  }
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #e1e5e9;
  padding: 12px 0 8px 0 !important;
  text-transform: capitalize;
  border-bottom: none;
}

.react-datepicker__week {
  margin-bottom: 0.15rem;
  &:last-child {
    margin-bottom: 0;
    border-radius: 4px;
  }
}

.react-datepicker {
  border-color: #e1e5e9 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  box-shadow:
    0px 8px 16px -2px rgba(27, 36, 44, 0.12),
    0px 2px 2px -1px rgba(27, 35, 44, 0.04);
  padding: 12px 0;
}

.react-datepicker__day {
  border-radius: 1px;
  box-sizing: content-box;
  padding: 0 0.15rem;
  margin: 0;
}

.react-datepicker__day.react-datepicker__day--in-range {
  background-color: hsla(210, 70%, 96%, 1);
  color: #121212;
}

// .react-datepicker__day.react-datepicker__day--keyboard-selected {
//   background-color: #eb597b;
// }

.react-datepicker__navigation {
  top: 12px !important;
  span {
    &::before {
      border-color: hsla(213, 82%, 57%, 1);
      border-width: 2px 2px 0 0;
    }
  }
}

.react-datepicker__current-month {
  color: hsla(213, 82%, 57%, 1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.react-datepicker__day-name {
  color: #a6a6a6;
  font-size: 10px;
  font-style: normal;
  text-transform: uppercase;
}

.react-datepicker__day--selected {
  background-color: #0393ff !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: hsla(210, 70%, 96%, 1) !important;
  color: #000;
}

.react-datepicker__close-icon {
  display: none;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #0393ff !important;
  color: #fff !important;
  border-radius: 4px;
}

.react-datepicker__navigation-icon {
  top: 8px !important;
}

.react-datepicker__day--today {
  background-color: #fff;
  border: 1px solid hsla(213, 82%, 57%, 1);
  border-radius: 4px;
  color: #000;
  font-weight: 400;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  background-image: url('/images/icons/datepickerArrow.svg') !important;
  background-repeat: no-repeat;
  background-position: center center;
  border-color: #fff !important;
  border-width: 0 !important;
  height: 12px !important;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(0deg) !important;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(182deg) !important;
}
