.CodeMirror-scroll{
  min-height: 200px !important;
  max-height: 200px !important;
}
.editor-toolbar{
  .fullscreen, .side-by-side, .quote, .image, .link, .separator{
    display: none !important;
  }
}
.cm-spell-error{
  background: none !important;
}