.Select__value-container--is-multi {
  height: 42px !important;
  flex-wrap: nowrap !important;
  white-space: nowrap;
  overflow: hidden !important;
  overflow-x: scroll !important;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Select__multi-value {
  display: inline-flex !important;
  min-width: fit-content !important;
}

.Select__menu-list {
  overflow: visible !important;
  max-height: initial !important;
}
